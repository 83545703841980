import React from "react";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/index.reducers";
import {navigate} from 'gatsby';


export const OpenRoute = ({ component: Component, ...rest }) => {
  return(
    <Component {...rest}></Component>
  )};

  export const isBrowser = typeof window !== 'undefined';


export const PrivateRoute = ({ component : Component, ...props }) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.userData);
  if (
    isAuthenticated
  ) {
   return(
     <Component {...props}></Component>
   )
  }
   else{
      if(isBrowser){
      navigate(`/login?redirect=${props.location.pathname}${props.location.search}`)
      return (
       <Component {...props} />
   )
      }
   }
   return null;

};



export const NoAuthRoute = ({ component: Component, ...rest }) => {
  const location=useLocation()
  const {isAuthenticated}=useSelector((state:RootState)=>state.userData);
  if (
    !isAuthenticated 
  ) {
   return(
     <Component {...rest}></Component>
   )
  }else{
      navigate((location && queryString.parse(location.search??"")?.redirect)?(queryString.parse(location.search).redirect as string):"/tasks")
      return (
          <Component {...rest} />
      )
  }
}
